// components
import { ChatBubble, ShoppingBag } from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  blog: getIcon('ic_blog'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      // { title: 'category', path: PATH_DASHBOARD.category.list, icon: ICONS.dashboard },
      // { title: 'Product', path: PATH_DASHBOARD.product.list, icon: ICONS.analytics },
      { title: 'Product Catalog', path: PATH_DASHBOARD.product.list, icon: ICONS.analytics },

      // { title: 'Brand', path: PATH_DASHBOARD.brands.list, icon: ICONS.user },
      { title: 'Subscription', path: PATH_DASHBOARD.subscription.list, icon: ICONS.ecommerce },
      { title: 'Sale', path: PATH_DASHBOARD.sale.list, icon: ICONS.analytics },
      { title: 'Order', path: PATH_DASHBOARD.order.list, icon: <ShoppingBag /> },
      { title: 'Chats', path: PATH_DASHBOARD.chat, icon: <ChatBubble /> },
      { title: 'Blog', path: PATH_DASHBOARD.blog.root, icon: ICONS.blog },
    ],
  },
];

export default sidebarConfig;
