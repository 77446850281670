// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  category: {
    create: path(ROOTS_DASHBOARD, '/category/create'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    edit: path(ROOTS_DASHBOARD, '/category/edit'),
  },
  product: {
    create: path(ROOTS_DASHBOARD, '/product/create'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    details: path(ROOTS_DASHBOARD, '/product/details'),
    edit: path(ROOTS_DASHBOARD, '/product/edit'),
  },
  service: {
    create: path(ROOTS_DASHBOARD, '/service/create'),
    edit: path(ROOTS_DASHBOARD, '/service/edit'),
  },
  brands: {
    create: path(ROOTS_DASHBOARD, '/brands/create'),
    list: path(ROOTS_DASHBOARD, '/brands/list'),
    edit: path(ROOTS_DASHBOARD, '/brands/edit'),
  },
  sale: {
    create: path(ROOTS_DASHBOARD, '/sale/create'),
    list: path(ROOTS_DASHBOARD, '/sale/list'),
    edit: path(ROOTS_DASHBOARD, '/sale/edit'),
  },

  subscription: {
    create: path(ROOTS_DASHBOARD, '/Subscriptions/create'),
    list: path(ROOTS_DASHBOARD, '/Subscriptions/list'),
    edit: path(ROOTS_DASHBOARD, '/Subscriptions/edit'),
  },
  order: {
    create: path(ROOTS_DASHBOARD, '/order/create'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    edit: path(ROOTS_DASHBOARD, '/order/edit'),
    detail: path(ROOTS_DASHBOARD, '/order/detail'),
  },
  notification: path(ROOTS_DASHBOARD, '/notification/list'),
  chat: path(ROOTS_DASHBOARD, '/chat/list'),
  message: path(ROOTS_DASHBOARD, '/chat/message'),
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    post: path(ROOTS_DASHBOARD, '/blog/post'),
    my: path(ROOTS_DASHBOARD, '/blog/myPosts'),
    edit: path(ROOTS_DASHBOARD, '/blog/edit'),
  },
  printful: {
    root: path(ROOTS_DASHBOARD, '/printful'),
    auth: path(ROOTS_DASHBOARD, '/printful/auth'),
    success: path(ROOTS_DASHBOARD, '/printful/success'),
    products: path(ROOTS_DASHBOARD, '/printful/products'),
    productDetails: path(ROOTS_DASHBOARD, '/printful/product/detail'),
    category: path(ROOTS_DASHBOARD, '/printful/category'),
  },
};

export const PATH_DOCS = '';
